import PastTournamentResultsComponent from "../../components/PastTournamentResultsComponent";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";

function PastTournamentResults() {
  const backgroundImage = "/wow-wallpaper4.avif";
  const { id } = useParams();

  return (
    <Layout backgroundImage={backgroundImage}>
      <PastTournamentResultsComponent id={id} />
    </Layout>
  );
}

export default PastTournamentResults;
