import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import useDecodedToken from "../utils/useDecodedToken";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../context/UserProfileContext";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenLg, setDropdownOpenLg] = useState(false);
  const { decodedJwt } = useDecodedToken();
  const battleNetTag = decodedJwt?.battleNetTag || "Characters";
  const dropdownRef = useRef(null);
  const dropdownRefLg = useRef(null);
  const navigate = useNavigate();
  const [selectedCharacter, setSelectedCharacter] = useState({});
  const { userProfile } = useUserProfile();
  const [characterName, setCharacterName] = useState(null);
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    if (userProfile && userProfile.selectedCharacter) {
      setSelectedCharacter(userProfile.selectedCharacter);
      setCharacterName(userProfile.selectedCharacter.characterName);
    }
  }, [userProfile]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdownLg = () => {
    setDropdownOpenLg(!dropdownOpenLg);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (dropdownRefLg.current && !dropdownRefLg.current.contains(event.target)) {
      setDropdownOpenLg(false);
    }
  };

  const handleLogout = () => {
    Cookies.remove("jwt");
    window.location.href = "/";
  };

  const handleNavigateToDashboard = () => {
    navigate("/dashboard");
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div className="bg-gray-900 w-full flex justify-between items-center p-4">
      <div
        className="flex items-center cursor-pointer"
        onClick={handleNavigateToDashboard}
      >
        <div className="bg-white p-1 rounded-full mr-4">
          <img src="/terra_logo.png" alt="Terra Logo" className="h-8 w-8" />
        </div>
        <span className="text-white text-4xl font-bold font-serif">TERRA</span>
      </div>
      <div className="hidden lg:flex items-center justify-center flex-grow">
        <a
          href="/account"
          className={`text-lg mx-4 ${activeLink === "characters" ? "text-blue-200" : "text-white"} hover:text-blue-200`}
          onClick={() => handleLinkClick("characters")}
        >
          Characters
        </a>
        <a
          href="/tournaments"
          className={`text-lg mx-4 ${activeLink === "tournaments" ? "text-blue-200" : "text-white"} hover:text-blue-200`}
          onClick={() => handleLinkClick("tournaments")}
        >
          Tournaments
        </a>
        <a
          href="/past-tournaments"
          className={`text-lg mx-4 ${activeLink === "past-tournaments" ? "text-blue-200" : "text-white"} hover:text-blue-200`}
          onClick={() => handleLinkClick("past-tournaments")}
        >
          Past Tournaments
        </a>
        {decodedJwt?.role === "admin" && (
          <a
            href="/manage-tournaments"
            className={`text-lg mx-4 ${activeLink === "manage-tournaments" ? "text-blue-200" : "text-white"} hover:text-blue-200`}
            onClick={() => handleLinkClick("manage-tournaments")}
          >
            Manage Tournaments
          </a>
        )}
      </div>
      <div className="relative flex items-center lg:hidden" ref={dropdownRef}>
        <div className="flex items-center">
          {selectedCharacter.characterName ? (
            <span className="flex items-center mr-4">
              {selectedCharacter.mediaLinks &&
                selectedCharacter.mediaLinks.length > 0 && (
                  <img
                    src={selectedCharacter.mediaLinks[0].value}
                    alt={selectedCharacter.characterName}
                    className="h-8 w-8 rounded-full mr-2"
                  />
                )}
              <span className="text-white text-lg">
                {selectedCharacter.characterName}
              </span>
            </span>
          ) : (
            <span className="text-white text-lg mr-2">
              {characterName || battleNetTag}
            </span>
          )}
          <button onClick={toggleDropdown} className="text-white text-lg">
            <i className="fas fa-bars"></i>
          </button>
        </div>
        {dropdownOpen && (
          <div className="absolute z-10 right-0 mt-40 w-48 bg-black text-white rounded-md shadow-lg">
            <a
              href="/account"
              className="block px-4 py-2 hover:text-blue-200"
              onClick={() => handleLinkClick("characters")}
            >
              Characters
            </a>
            <a
              href="/tournaments"
              className="block px-4 py-2 hover:text-blue-200"
              onClick={() => handleLinkClick("tournaments")}
            >
              Tournaments
            </a>
            <a
              href="/past-tournaments"
              className="block px-4 py-2 hover:text-blue-200"
              onClick={() => handleLinkClick("past-tournaments")}
            >
              Past Tournaments
            </a>
            {decodedJwt?.role === "admin" && (
              <a
                href="/manage-tournaments"
                className="block px-4 py-2 hover:text-blue-200"
                onClick={() => handleLinkClick("manage-tournaments")}
              >
                Manage Tournaments
              </a>
            )}
            <button
              onClick={handleLogout}
              className="block w-full text-left px-4 py-2 hover:text-blue-200"
            >
              Logout
            </button>
          </div>
        )}
      </div>
      <div className="relative hidden lg:flex items-center" ref={dropdownRefLg}>
        <button onClick={toggleDropdownLg} className="flex items-center text-white text-lg">
          {selectedCharacter.mediaLinks && selectedCharacter.mediaLinks.length > 0 && (
            <img
              src={selectedCharacter.mediaLinks[0].value}
              alt={selectedCharacter.characterName}
              className="h-8 w-8 rounded-full mr-2"
            />
          )}
          {selectedCharacter.characterName || characterName || battleNetTag}
        </button>
        {dropdownOpenLg && (
          <div className="absolute z-10 right-0 mt-20 w-48 bg-black text-white rounded-md shadow-lg">
            <button
              onClick={handleLogout}
              className="block w-full text-left px-4 py-2 hover:text-blue-200"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;