import React, { useEffect, useState } from "react";
import { useUserProfile } from "../context/UserProfileContext";
import {
  fetchUserCharacters,
  selectUserCharacter,
  fetchRegisteredTournament,
} from "../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";

const CharacterSelection = ({ token }) => {
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(false);
  const { userProfile, setUserProfile } = useUserProfile();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userProfile && userProfile.selectedCharacter) {
      setSelectedCharacter(userProfile.selectedCharacter);
    }
  }, [userProfile]);

  useEffect(() => {
    if (!token) return;

    const fetchCharacters = async () => {
      try {
        const data = await fetchUserCharacters(token);
        setCharacters(data);
      } catch (error) {
        console.error("Error fetching characters:", error);
        toast.error("Error fetching characters");
      } finally {
        setLoading(false);
      }
    };

    fetchCharacters();
  }, [token]);

  const handleCardClick = async (character) => {
    const {
      href,
      protectedHref,
      name,
      id,
      realm,
      playable_class,
      playable_race,
      gender,
      faction,
      level,
      mediaLinks,
      mythicPlusScore,
      guildId,
      guildName,
      guildRealm,
    } = character;

    const characterData = {
      character: { href },
      protected_character: { protectedHref },
      id,
      name,
      realm: {
        ...realm,
        key: { href: realm.href },
      },
      playable_class: {
        ...playable_class,
        key: { href: playable_class.href },
      },
      playable_race: {
        ...playable_race,
        key: { href: playable_race.href },
      },
      gender,
      faction,
      level,
      guildId,
      guildName,
      guildRealm,
      mediaLinks: mediaLinks.map((link) => ({
        key: link.key,
        value: link.value,
      })),
      mythicPlusScore: mythicPlusScore,
    };

    try {
      const userProfile = await selectUserCharacter(token, characterData);
      setUserProfile(userProfile);
      toast.success(`Selected ${character.name} for the tournament!`);
    } catch (error) {
      console.error("Error selecting character:", error);
      toast.error(
        "Cannot select character if you are already signed up for a tournament."
      );
    }
  };

  const handleSelectedCharacterClick = async (selectedCharacter) => {
    try {
      const response = await fetchRegisteredTournament(
        token,
        selectedCharacter.id
      );
      console.error("Tournament standings response:", response);

      if (Object.keys(response).length === 0) {
        navigate("/tournaments");
      } else {
        navigate(`/tournaments/${response.tournament.id}`);
      }
    } catch (error) {
      console.error("Error fetching tournament standings:", error);
      toast.error("Error fetching tournament standings");
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container mx-auto p-4">
      {characters.length > 0 && (
        <h1 className="text-4xl font-bold mb-10 text-center text-white font-serif">
          Choose your tournament character!
        </h1>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
        {characters.length > 0 ? (
          characters.map((character) => {
            const isSelected =
              selectedCharacter &&
              selectedCharacter.characterName === character.name;

            return (
              <div
                key={character.id}
                className={`p-4 rounded-lg border shadow-xl transform transition duration-500 hover:scale-105 flex flex-col items-center group ${
                  isSelected
                    ? "bg-blue-100 cursor-pointer"
                    : "bg-gray-300 hover:border-black cursor-pointer"
                }`}
                onClick={() =>
                  isSelected
                    ? handleSelectedCharacterClick(selectedCharacter)
                    : handleCardClick(character)
                }
              >
                <img
                  src={character.mediaLinks[2].value}
                  alt={character.name}
                  className="w-full lg:h-[45vh] object-cover transform transition duration-500 group-hover:scale-125"
                />
                <h2 className="text-3xl font-bold text-center mb-4 font-serif">
                  {character.name}
                </h2>
                <p className="text-lg text-center">
                  {character.playable_race.name} {character.playable_class.name}
                </p>
                <p className="text-lg text-center">{character.realm.name}</p>
                <p className="text-xl font-bold">
                  Mythic+ score: {character.mythicPlusScore}
                </p>
                <div className="mt-2">
                  {character.faction.name === "Horde" ? (
                    <img
                      src="/horde-logo3.png"
                      alt="Horde"
                      className="w-16 h-24"
                    />
                  ) : (
                    <img
                      src="/alliance-logo2.jpg"
                      alt="Alliance"
                      className="w-18 h-24"
                    />
                  )}
                </div>
                {!isSelected && (
                  <button
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300 block lg:hidden"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCardClick(character);
                    }}
                  >
                    Select character
                  </button>
                )}
              </div>
            );
          })
        ) : (
          <p className="text-4xl font-bold text-center text-white">
            No characters found
          </p>
        )}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CharacterSelection;
