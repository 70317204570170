const Loading = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-white">
      <div className="text-4xl font-bold">Loading</div>
      <div className="ml-4 animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
};

export default Loading;
