import React, { useEffect, useState } from "react";
import { fetchAllTournaments, fetchTournamentStandings } from "../../api/api";
import { useUserProfile } from "../../context/UserProfileContext";
import TournamentsList from "../../components/TournamentList";
import useDecodedToken from "../../utils/useDecodedToken";
import ErrorModal from "../../components/modals/ErrorModal";
import Layout from "../../components/Layout";

const Tournaments = () => {
  const backgroundImage = "/wow-wallpaper4.avif";

  const { token } = useDecodedToken();
  const [tournaments, setTournaments] = useState([]);
  const { userProfile } = useUserProfile();

  const [errorMessage, setErrorMessage] = useState("");

  const closeModal = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (!token) return;
    const fetchAllTournamentsAsync = async (token) => {
      try {
        const data = await fetchAllTournaments(token);
        const activeTournaments = data.filter(tournament => !tournament.completed);
        setTournaments(activeTournaments);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchAllTournamentsAsync(token);
  }, [token]);

  useEffect(() => {
    const selectedCharacter = userProfile?.selectedCharacter;
    if (token && selectedCharacter) {
      const fetchStandings = async () => {
        const status = {};
        for (const tournament of tournaments) {
          try {
            const response = await fetchTournamentStandings(
              token,
              tournament.id
            );
            const standings = response.standings || [];
            status[tournament.id] = standings.some(
              (entry) => entry.characterName === selectedCharacter.characterName
            );
          } catch (error) {
            console.error("Error fetching tournament standings:", error);
            status[tournament.id] = false;
          }
        }
      };

      fetchStandings();
    }
  }, [token, userProfile, tournaments]);

  return (
    <Layout backgroundImage={backgroundImage}>
      <TournamentsList tournaments={tournaments} />
      <ErrorModal errorMessage={errorMessage} closeModal={closeModal} />
    </Layout>
  );
};

export default Tournaments;