import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const useDecodedToken = () => {
  const token = Cookies.get("jwt") || null;
  let decodedJwt = null;

  if (token) {
    decodedJwt = jwtDecode(token);
  } else {
    console.error("No JWT found in cookies");
    window.location.href = "/";
  }

  return { token, decodedJwt };
};

export default useDecodedToken;
