import React, { useEffect, useState } from "react";
import { useUserProfile } from "../../context/UserProfileContext";
import { fetchRegisteredTournament, fetchAllTournaments } from "../../api/api";
import CharacterSelection from "../../components/CharacterSelection";
import TournamentsList from "../../components/TournamentList";
import Layout from "../../components/Layout";
import ErrorModal from "../../components/modals/ErrorModal";
import TournamentStandingsComponent from "../../components/TournamentStandingsComponent";
import useTokenAndProfile from "../../components/hooks/FetchUserProfile";
import Loading from "../../components/Loading";

function Dashboard() {
  const { token } = useTokenAndProfile();
  const backgroundImage = "/wow-wallpaper4.avif";
  const [selectedCharacter, setSelectedCharacter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [characterTournamentStandings, setCharacterTournamentStandings] =
    useState(false);
  const [tournaments, setTournaments] = useState([]);
  const { userProfile } = useUserProfile();

  useEffect(() => {
    if (userProfile) {
      setSelectedCharacter(userProfile.selectedCharacter);
    }
    setLoading(false);
  }, [userProfile]);

  useEffect(() => {
    if (!token) return;
    if (selectedCharacter) {
      const fetchRegistrationAndTournament = async (selectedCharacter) => {
        if (!selectedCharacter || !selectedCharacter.id) return;
        const tournamentStandings = await fetchRegisteredTournament(
          token,
          selectedCharacter.id
        );

        if (tournamentStandings["standings"]) {
          setCharacterTournamentStandings(tournamentStandings);
        }
      };

      fetchRegistrationAndTournament(selectedCharacter);
    }
  }, [selectedCharacter, token]);

  useEffect(() => {
    if (!token) return;
    if (selectedCharacter && !characterTournamentStandings) {
      const fetchAllTournamentsAsync = async () => {
        const allTournaments = await fetchAllTournaments(token);
        setTournaments(allTournaments);
      };

      fetchAllTournamentsAsync();
    }
  }, [token, selectedCharacter, characterTournamentStandings]);

  const closeModal = () => {
    setErrorMessage("");
  };

   const getReturnComponent = () => {
    // Check if a character is selected
    if (!selectedCharacter) {
      return <CharacterSelection token={token} setLoading={setLoading} />;
    }
  
    // Filter tournaments to include only those that are not completed
    const activeTournaments = tournaments.filter(tournament => !tournament.completed);
  
    // If a character is selected but there are no tournament standings
    if (!characterTournamentStandings) {
      return <TournamentsList tournaments={activeTournaments} />;
    }
  
    // If a character and tournament standings are available
    return (
      <TournamentStandingsComponent
        id={characterTournamentStandings.tournament.id}
      />
    );
  };
  
  if (loading) {
    return <Loading />;
  }

  return (
    <Layout backgroundImage={backgroundImage}>
      {getReturnComponent()}
      <ErrorModal errorMessage={errorMessage} closeModal={closeModal} />
    </Layout>
  );
}

export default Dashboard;