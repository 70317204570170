import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../context/UserProfileContext";
import { fetchTournamentStandings } from "../api/api";
import useTokenAndProfile from "./hooks/FetchUserProfile";
import { toast, ToastContainer } from "react-toastify";

const PastTournamentsList = ({ tournaments }) => {
  const { token } = useTokenAndProfile();
  const navigate = useNavigate();
  const [selectedCharacter, setSelectedCharacter] = useState(false);
  const { userProfile } = useUserProfile();

  const handleFinalResultsClick = (tournamentId) => {
    navigate(`/past-tournaments/${tournamentId}`);
  };

  useEffect(() => {
    if (!token) return;

    const fetchStandings = async () => {
      const status = {};

      for (const tournament of tournaments) {
        try {
          const response = await fetchTournamentStandings(token, tournament.id);
          const standings = response.standings || [];
          status[tournament.id] = standings.some(
            (entry) => entry.characterName === selectedCharacter.characterName
          );
        } catch (error) {
          console.error("Error fetching tournament standings:", error);
          toast.error("Error fetching tournament standings");
          status[tournament.id] = false;
        }
      }
    };

    fetchStandings();
  }, [token, selectedCharacter, tournaments]);

  const images = [
    "/wow-wallpaper.jpg",
    "/wow-wallpaper2.jpg",
    "/wow-wallpaper3.jpg"
  ];

  if (tournaments.length === 0) {
    return (
      <div className="text-white flex justify-center mt-20 font-bold text-4xl">
        There is no completed tournaments
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center space-y-8 p-4 mt-8">
      {tournaments.map((tournament, index) => (
        <div
          key={index}
          className="flex flex-col lg:flex-row items-center w-[90%] h-auto lg:h-[25vh] bg-gray-300 rounded-lg shadow-lg p-4 transform transition duration-500 lg:hover:scale-105"
        >
          <img
            src={images[index % images.length]}
            alt={tournament.name}
            className="w-1/3 h-[12vh] lg:w-[10vw] lg:h-[15vh] rounded-full object-cover mx-4"
          />
          <div className="mt-4 lg:mt-0 flex-grow text-center">
            <h2 className="text-2xl font-bold text-black group-hover:text-white">
              {tournament.name}
            </h2>
            <p className="text-lg text-black group-hover:text-white">
              {tournament.description}
            </p>
            <div className="lg:flex lg:justify-center lg:space-x-2">
              <p className="lg:text-lg text-black group-hover:text-white">
                <strong>Tournament Period:</strong>
              </p>
              <p className="lg:text-lg text-black group-hover:text-white">
                {new Date(tournament.startDate).toLocaleDateString('en-GB')} - {new Date(tournament.endDate).toLocaleDateString('en-GB')}
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row mt-4 lg:mr-10 lg:mt-0">
            <button
              onClick={() => handleFinalResultsClick(tournament.id)}
              className="ml-auto lg:ml-0 px-4 py-2 bg-orange-400 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              Final results
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PastTournamentsList;