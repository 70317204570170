import React, { useState } from "react";

const TournamentModal = ({ isOpen, onClose, onSubmit }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");
  const [type, setType] = useState("daily");

  const handleSubmit = (e) => {
    e.preventDefault();
    const now = new Date();
    const regStart = new Date(registrationStartDate);
    const regEnd = new Date(registrationEndDate);
    const start = new Date(startDate);

    if (regStart <= now || regEnd <= now) {
      alert("Registration dates must be in the future.");
      return;
    }

    if (regStart >= regEnd) {
      alert("Registration start date must be before the end date.");
      return;
    }

    if (start <= now) {
      alert("Start date must be in the future.");
      return;
    }

    onSubmit({
      name,
      description,
      startDate,
      registrationStartDate,
      registrationEndDate,
      type,
    });

    setName("");
    setDescription("");
    setStartDate("");
    setRegistrationStartDate("");
    setRegistrationEndDate("");
    setType("daily");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md md:max-w-lg lg:max-w-xl">
        <h2 className="text-2xl font-bold mb-4">Create Tournament</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Start Date</label>
            <input
              type="datetime-local"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              min="2024-09-01T00:00"
              max="2026-12-31T23:59"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">
              Registration Start Date
            </label>
            <input
              type="datetime-local"
              value={registrationStartDate}
              onChange={(e) => setRegistrationStartDate(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              min="2024-09-01T00:00"
              max="2026-12-31T23:59"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Registration End Date</label>
            <input
              type="datetime-local"
              value={registrationEndDate}
              onChange={(e) => setRegistrationEndDate(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              min="2024-09-01T00:00"
              max="2026-12-31T23:59"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Type</label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          <div className="flex flex-col sm:flex-row sm:justify-end justify-center">
            <button
              type="button"
              onClick={onClose}
              className="mr-0 sm:mr-4 mb-2 sm:mb-0 px-4 py-2 bg-gray-500 text-white rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Add Tournament
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TournamentModal;