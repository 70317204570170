import React from "react";
import "./index.css";
import ReactDOM from "react-dom/client";
import SignIn from "./pages/signin/SignIn";
import SignUp from "./pages/signup/SignUp";
import Dashboard from "./pages/dashboard/Dashboard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserProfileProvider } from "./context/UserProfileContext";
import Tournaments from "./pages/dashboard/Tournaments";
import ManageTournaments from "./pages/dashboard/ManageTournaments";
import TournamentStandings from "./pages/dashboard/TournamentStandings";
import Account from "./pages/dashboard/Account";
import PastTournaments from "./pages/dashboard/PastTournaments";
import PastTournamentResults from "./pages/dashboard/PastTournamentResults";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <UserProfileProvider>
      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/account" element={<Account />} />
          <Route path="/tournaments" element={<Tournaments />} />
          <Route path="/past-tournaments" element={<PastTournaments />} />
          <Route path="/past-tournaments/:id" element={<PastTournamentResults />} />
          <Route path="/tournaments/:id" element={<TournamentStandings />} />
          <Route path="/manage-tournaments/*" element={<ManageTournaments />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
        </Routes>
      </Router>
    </UserProfileProvider>
  </React.StrictMode>
);
