import { useEffect, useState } from "react";
import useDecodedToken from "../../utils/useDecodedToken";
import { useUserProfile } from "../../context/UserProfileContext";
import { fetchUserProfile } from "../../api/api";

const useTokenAndProfile = () => {
  const { token } = useDecodedToken(); // Token from decoded JWT
  const { userProfile, setUserProfile } = useUserProfile(); // Context for user profile
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (token) return; // If token already exists, skip

    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get("token");

    if (urlToken) {
      document.cookie = `jwt=${urlToken}; path=/; SameSite=Lax`;
      window.location.reload(); // Reload to reflect the new token in the app state
    }
  }, [token]);

  useEffect(() => {
    if (!token || userProfile) {
      setLoading(false); // Don't need to fetch the profile if token is missing or userProfile exists
      return;
    }

    const getUserProfile = async () => {
      try {
        const profileData = await fetchUserProfile(token); // Fetch profile with the token
        setUserProfile(profileData); // Update the context with fetched profile
      } catch (error) {
        setErrorMessage("Failed to load user profile: " + error.message);
      } finally {
        setLoading(false); // Set loading to false after fetching the profile
      }
    };

    getUserProfile();
  }, [token, userProfile, setUserProfile]);

  return { token, setUserProfile };
};

export default useTokenAndProfile;
