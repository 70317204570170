import React, { createContext, useContext, useState } from "react";

// Create the context
const UserProfileContext = createContext();

// Create the provider component
export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};

// Custom hook to access the UserProfileContext
export const useUserProfile = () => {
  return useContext(UserProfileContext);
};
