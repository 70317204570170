import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

function SignIn() {
  const navigate = useNavigate();

  useEffect(() => {
    const jwt = Cookies.get("jwt");
    if (jwt) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleClick = () => {
    window.location.href = `${API_URL}/auth/blizzard`;
  };

  return (
    <section className="bg-white min-h-screen flex items-center justify-center relative">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: "url('/wow-wallpaper.jpg')" }}
      ></div>
      <div className="relative z-10 bg-white bg-opacity-80 p-8 rounded-lg shadow-lg w-[90vw] lg:w-1/3 h-[50vh] lg:h-1/3">
        <div className="bg-black text-white p-4 flex items-center justify-center">
          <div className="bg-white rounded-full p-2">
            <img src="/terra_logo.png" alt="Terra Logo" className="h-8 w-8" />
          </div>
          <span className="text-4xl font-bold ml-2">TERRA</span>
        </div>
        <h2 className="text-center text-2xl mt-4">Welcome Back</h2>
        <div className="flex flex-col h-1/2 lg:h-[20vh] justify-center items-center text-center">
          <div className="mb-10 text-xl">
            Sign in with your BattleNet Account
          </div>
          <button
            type="button"
            className="inline-block shrink-0 rounded-md border bg-blue-500 px-12 py-3 text-sm font-medium text-white transition hover:bg-blue-800"
            onClick={handleClick}
          >
            Sign in
          </button>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
