import React, { useEffect, useState } from "react";
import { fetchTournamentStandings, fetchTournamentHistoryById } from "../api/api";
import useTokenAndProfile from "./hooks/FetchUserProfile";
import { useUserProfile } from "../context/UserProfileContext";

const PastTournamentResultsComponent = ({ id }) => {
  const { token } = useTokenAndProfile();
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tournamentName, setTournamentName] = useState("");
  const { userProfile } = useUserProfile();
  const [characterName, setCharacterName] = useState(null);

  useEffect(() => {
    if (userProfile && userProfile.selectedCharacter && userProfile.selectedCharacter.characterName) {
      setCharacterName(userProfile.selectedCharacter.characterName);
    }
  }, [userProfile]);

  useEffect(() => {
    if (!token) return;

    const fetchTournamentHistoryAsync = async (token, id) => {
      try {
        const tournamentStandings = await fetchTournamentStandings(token, id);
        setTournamentName(tournamentStandings.tournament.name);
        const tournamentData = await fetchTournamentHistoryById(token, id);
        console.error(tournamentData);
        const sortedStandings = tournamentData.sort((a, b) => b.finalScore - a.finalScore);
        setStandings(sortedStandings);
      } catch (error) {
        console.error("Error fetching tournament standings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentHistoryAsync(token, id);
  }, [token, id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (standings.length === 0) {
    return (
      <div className="text-white flex justify-center mt-20 font-bold text-4xl">
        No standings available
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto lg:flex lg:justify-center mt-10">
          <h1 className="text-4xl font-bold leading-6 text-white">{tournamentName}</h1>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xl font-bold text-gray-900 sm:pl-6">
                      Character Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-xl font-bold text-gray-900">
                      Rank
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-xl font-bold text-gray-900">
                      Score
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {standings.map((character, index) => (
                    <tr
                      key={index}
                      className={characterName === character.characterName ? "bg-blue-100" : ""}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-gray-900 sm:pl-6">
                        {character.characterName} - {character.characterRealm}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500">{index + 1}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500">{character.finalScore}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastTournamentResultsComponent;