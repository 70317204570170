const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

const handleFetch = async (url, options, token) => {
  const response = await fetch(url, {
    ...options,
    headers: { ...options.headers, Authorization: `Bearer ${token}` },
  });

  let responseBody;

  try {
    // Attempt to parse the response as JSON
    responseBody = await response.json();
  } catch (error) {
    // Handle cases where response is empty or not valid JSON
    if (response.status !== 204) {
      // Ignore empty bodies for 204 status (No Content)
      console.error("Failed to parse JSON:", error);
    }
    responseBody = {}; // Default to empty object if no body
  }

  if (response.status === 401) {
    // Handle specific conditions where the token should be deleted
    if (
      responseBody?.message === "Token expired" ||
      responseBody?.error === "Invalid token"
    ) {
      document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.href = "/";
      throw new Error("Unauthorized: Token has expired or is invalid.");
    } else {
      throw new Error("Unauthorized: Insufficient permissions.");
    }
  }

  if (!response.ok) {
    const errorMessage = responseBody?.message || "An error occurred";
    throw new Error(errorMessage);
  }

  return responseBody || {};
};

export const fetchUserCharacters = async (token) => {
  return handleFetch(
    `${API_URL}/users/characters`,
    {
      method: "GET",
    },
    token
  );
};

export const createTournament = async (token, tournament) => {
  return handleFetch(
    `${API_URL}/tournament`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tournament),
    },
    token
  );
};

export const fetchAllTournaments = async (token) => {
  return handleFetch(
    `${API_URL}/tournament`,
    {
      method: "GET",
    },
    token
  );
};

export const fetchTournamentById = async (token, id) => {
  return handleFetch(
    `${API_URL}/tournaments/${id}`,
    {
      method: "GET",
    },
    token
  );
};

export const signupForTournament = async (token, id) => {
  return handleFetch(
    `${API_URL}/tournament/${id}/signup`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
    token
  );
};

export const cancelTournamentSignup = async (token) => {
  return handleFetch(
    `${API_URL}/tournament/signup/cancel`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
    token
  );
};

export const fetchTournamentStandings = async (token, id) => {
  return handleFetch(
    `${API_URL}/tournament/${id}/standings`,
    {
      method: "GET",
    },
    token
  );
};

export const selectUserCharacter = async (token, characterData) => {
  return handleFetch(
    `${API_URL}/users/character/select`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(characterData),
    },
    token
  );
};

export const fetchUserProfile = async (token) => {
  return handleFetch(
    `${API_URL}/users/me`,
    {
      method: "GET",
    },
    token
  );
};

export const fetchRegisteredTournament = async (token, selectedCharacterId) => {
  return handleFetch(
    `${API_URL}/tournament/character/${selectedCharacterId}/standings`,
    {
      method: "GET",
    },
    token
  );
};

export const deleteTournament = async (token, id) => {
  return handleFetch(
    `${API_URL}/tournament/${id}`,
    {
      method: "DELETE",
    },
    token
  );
};

export const fetchTournamentHistory = async (token) => {
  return handleFetch(
    `${API_URL}/tournament/history`,
    {
      method: "GET",
    },
    token
  );
};

export const fetchTournamentHistoryById = async (token, id) => {
  return handleFetch(
    `${API_URL}/tournament/${id}/history`,
    {
      method: "GET",
    },
    token
  );
};

export const fetchTournamentStandingsById = async (token, id) => {
  return handleFetch(
    `${API_URL}/tournament/${id}/standings`,
    {
      method: "GET",
    },
    token
  );
};

export const fetchCharacterStandings = async (token, characterId) => {
  return handleFetch(
    `${API_URL}/tournament/character/${characterId}/standings`,
    {
      method: "GET",
    },
    token
  );
};

export const updateTournamentStandings = async (token, id) => {
  return handleFetch(
    `${API_URL}/tournament/${id}/update`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
    token
  );
};