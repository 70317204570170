import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cancelTournamentSignup, signupForTournament } from "../api/api";
import { useUserProfile } from "../context/UserProfileContext";
import { fetchTournamentStandings } from "../api/api";
import useTokenAndProfile from "./hooks/FetchUserProfile";
import { toast, ToastContainer } from "react-toastify";

const TournamentsList = ({ tournaments }) => {
  const { token } = useTokenAndProfile();
  const navigate = useNavigate();
  const [signUpStatus, setSignUpStatus] = useState({});
  const [selectedCharacter, setSelectedCharacter] = useState(false);
  const { userProfile } = useUserProfile();

  useEffect(() => {
    if (userProfile && userProfile.selectedCharacter) {
      setSelectedCharacter(userProfile.selectedCharacter);
    }
  }, [userProfile]);

  const handleViewRankings = (tournamentId) => {
    navigate(`/tournaments/${tournamentId}`);
  };

  const handleSignup = async (id) => {
    if (!token) return;
    try {
      await signupForTournament(token, id);
      setSignUpStatus((prevStatus) => ({ ...prevStatus, [id]: true }));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSignout = async (id) => {
    if (!token) return;
    try {
      await cancelTournamentSignup(token);
      setSignUpStatus((prevStatus) => ({ ...prevStatus, [id]: false }));
    } catch (error) {
      setSignUpStatus((prevStatus) => ({ ...prevStatus, [id]: false }));
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (!token) return;

    const fetchStandings = async () => {
      const status = {};

      for (const tournament of tournaments) {
        try {
          const response = await fetchTournamentStandings(token, tournament.id);
          const standings = response.standings || [];
          status[tournament.id] = standings.some(
            (entry) => entry.characterName === selectedCharacter.characterName
          );
        } catch (error) {
          console.error("Error fetching tournament standings:", error);
          toast.error("Error fetching tournament standings");
          status[tournament.id] = false;
        }
      }
      setSignUpStatus(status);
    };

    fetchStandings();
  }, [token, selectedCharacter, tournaments]);

  // Array of images
  const images = [
    "/wow-wallpaper.jpg",
    "/wow-wallpaper2.jpg",
    "/wow-wallpaper3.jpg"
  ];

  const calculateTimeDifference = (futureDate) => {
    const now = new Date();
    const diff = futureDate - now;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${days} days, ${hours} hours, ${minutes} minutes`;
  };

  if (tournaments.length === 0) {
    return (
      <div className="text-white flex justify-center mt-20 font-bold text-4xl">
        There are no active tournaments
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center space-y-8 p-4 mt-8">
      {tournaments.map((tournament, index) => {
        const now = new Date();
        const startDate = new Date(tournament.startDate);
        const registrationStartDate = new Date(tournament.registrationStartDate);
        const registrationEndDate = new Date(tournament.registrationEndDate);

        const isTournamentInFuture = startDate > now;
        const isRegistrationNotOpen = registrationStartDate > now;
        const isRegistrationClosed = registrationEndDate < now;

        return (
          <div
            key={index}
            className="flex flex-col lg:flex-row items-center w-[90%] h-auto lg:h-[25vh] bg-gray-300 rounded-lg shadow-lg p-4 transform transition duration-500 lg:hover:scale-105"
          >
            <img
              src={images[index % images.length]}
              alt={tournament.name}
              className="w-1/3 h-[12vh] lg:w-[10vw] lg:h-[15vh] rounded-full object-cover mx-4"
            />
            <div className="mt-4 lg:mt-0 flex-grow text-center">
              <h2 className="text-2xl font-bold text-black group-hover:text-white">
                {tournament.name}
              </h2>
              <p className="text-lg text-black group-hover:text-white">
                {tournament.description}
              </p>
              {isTournamentInFuture ? (
                <p className="text-lg text-black group-hover:text-white">
                  Tournament starts in {calculateTimeDifference(startDate)}
                </p>
              ) : (
                <div className="lg:flex lg:justify-center lg:space-x-2">
                  <p className="lg:text-lg text-black group-hover:text-white">
                    <strong>Tournament Period:</strong>
                  </p>
                  <p className="lg:text-lg text-black group-hover:text-white">
                    {new Date(tournament.startDate).toLocaleDateString('en-GB')} - {new Date(tournament.endDate).toLocaleDateString('en-GB')}
                  </p>
                </div>
              )}
              {isRegistrationNotOpen ? (
                <p className="text-lg text-black group-hover:text-white">
                  Registration starts in {calculateTimeDifference(registrationStartDate)}
                </p>
              ) : isRegistrationClosed ? (
                <p className="text-lg text-black group-hover:text-white">
                  Registration is closed
                </p>
              ) : (
                <div className="lg:flex lg:justify-center lg:space-x-2">
                  <p className="lg:text-lg text-black group-hover:text-white">
                    <strong>Registration Period:</strong>
                  </p>
                  <p className="lg:text-lg text-black group-hover:text-white">
                    {new Date(tournament.registrationStartDate).toLocaleDateString('en-GB')} - {new Date(tournament.registrationEndDate).toLocaleDateString('en-GB')}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col lg:flex-row mt-4 lg:mt-0">
              <button
                onClick={() => handleViewRankings(tournament.id)}
                className={`ml-auto lg:ml-0 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300 ${
                  isRegistrationNotOpen || isRegistrationClosed ? "lg:mr-8" : ""
                }`}
              >
                Tournament Rankings
              </button>
              {!isRegistrationNotOpen && !isRegistrationClosed && (
                <>
                  {signUpStatus[tournament.id] ? (
                    <button
                      onClick={() => handleSignout(tournament.id)}
                      className="mt-2 lg:mt-0 lg:ml-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
                    >
                      Sign out
                    </button>
                  ) : (
                    <button
                      onClick={() => handleSignup(tournament.id)}
                      className="mt-2 lg:mt-0 lg:ml-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition duration-300"
                    >
                      Sign up
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        );
      })}
      <ToastContainer />
    </div>
  );
};

export default TournamentsList;