import React from "react";

const ConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    <div>
      <p>Are you sure you want to proceed?</p>
      <div className="flex justify-center mt-4">
        <button
          onClick={onConfirm}
          className="bg-green-500 text-white px-4 py-2 mr-2 rounded"
        >
          Yes
        </button>
        <button
          onClick={onCancel}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          No
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
