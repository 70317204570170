import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState({
    userName: false,
    email: false,
    password: false,
    repeatPassword: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const jwt = Cookies.get("jwt");
    if (jwt) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleClick = async (event) => {
    const emailRegex = /\S+@\S+\.\S+/;
    let errors = {
      userName: false,
      email: false,
      password: false,
      repeatPassword: false,
    };

    if (firstName === "") {
      errors.firstName = true;
    }
    if (!emailRegex.test(email)) {
      errors.email = true;
    }
    if (password === "") {
      errors.password = true;
    }
    if (password !== repeatPassword) {
      errors.repeatPassword = true;
    }
    if (errors.email || errors.password || errors.repeatPassword) {
      event.preventDefault();
    }

    if (!errors.email && !errors.password && !errors.repeatPassword) {
      event.preventDefault();

      try {
        toast.success(
          "Account successfully created. You can now log in with your new account."
        );

        navigate("/signin");
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
    }

    setError(errors);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setError((prevError) => ({ ...prevError, firstName: false }));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError((prevError) => ({ ...prevError, email: false }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError((prevError) => ({ ...prevError, password: false }));
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
    setError((prevError) => ({ ...prevError, repeatPassword: false }));
  };

  return (
    <section className="bg-white min-h-screen flex items-center justify-center relative">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: "url('/wow-wallpaper2.jpg')" }}
      ></div>
      <div className="relative z-10 bg-white bg-opacity-80 p-8 rounded-lg shadow-lg w-[90vw] lg:w-full">
        <div className="bg-black text-white p-4 flex items-center justify-center">
          <div className="bg-white rounded-full p-2">
            <img src="/terra_logo.png" alt="Terra Logo" className="h-8 w-8" />
          </div>
          <span className="text-4xl font-bold ml-2">TERRA</span>
        </div>
        <h2 className="text-center text-2xl mt-4">Create An Account</h2>
        <form action="#" className="mt-2 grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <label
              htmlFor="FirstName"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              id="FirstName"
              name="first_name"
              className="mt-1 w-full rounded-md border-2 border-gray-300 bg-white text-sm text-gray-700 shadow-sm p-2"
              value={firstName}
              onChange={handleFirstNameChange}
            />
            {error.firstName && (
              <p className="text-red-500">Please enter your first name</p>
            )}
          </div>
          <div className="col-span-6">
            <label
              htmlFor="Email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>

            <input
              type="email"
              id="Email"
              className="mt-1 w-full rounded-md border-2 border-gray-300 bg-white text-sm text-gray-700 shadow-sm p-2"
              value={email}
              onChange={handleEmailChange}
            />
            {error.email && (
              <p className="text-red-500">Please enter a valid email</p>
            )}
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="Password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="Password"
              name="password"
              className="mt-1 w-full rounded-md border-2 border-gray-300 bg-white text-sm text-gray-700 shadow-sm p-2"
              value={password}
              onChange={handlePasswordChange}
            />
            {error.password && (
              <p className="text-red-500">Please enter a password</p>
            )}
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="PasswordConfirmation"
              className="block text-sm font-medium text-gray-700"
            >
              Password Confirmation
            </label>
            <input
              type="password"
              id="PasswordConfirmation"
              name="password_confirmation"
              className="mt-1 w-full rounded-md border-2 border-gray-300 bg-white text-sm text-gray-700 shadow-sm p-2"
              value={repeatPassword}
              onChange={handleRepeatPasswordChange}
            />
            {error.repeatPassword && (
              <p className="text-red-500">Please re-enter your password</p>
            )}
          </div>

          <div className="col-span-6 sm:flex sm:items-center sm:gap-4 justify-center">
            <button
              type="button"
              className="inline-block shrink-0 rounded-md border bg-blue-500 px-12 py-3 text-sm font-medium text-white transition hover:bg-blue-800"
              onClick={handleClick}
            >
              Create an account
            </button>

            <p className="mt-4 text-sm text-gray-500 sm:mt-0">
              Already have an account? &nbsp;
              <a href="/signin" className="text-gray-700 underline">
                Sign in
              </a>
            </p>
          </div>
        </form>
      </div>
      <ToastContainer />
    </section>
  );
}

export default SignUp;
